import { obterLinkParaSkills } from "@utils/obterLinkParaSkills"
import { Botao } from "@xapps/design-system"
import React from "react"
import { SkillLogo } from "./SkillLogo"

export function OutrasTecnologias({ relacionadas }) {
  return (
    <div className="col-span-full text-white text-center bg-primary w-full py-24 md:py-32 px-6 md:px-32">
      <div className="container col-span-full mx-auto grid grid-cols-12 gap-x-4">
        <h2 className="col-span-full text-sm md:text-lg mb-6 font-bold">
          Quer contratar desenvolvedores especializados em outras tecnologias?
        </h2>
        <div className="col-span-full grid grid-cols-4 lg:grid-cols-12 gap-6 justify-items-center text-sm md:text-base ">
          {relacionadas?.map((relacionado, index) => (
            <a
              className={`flex items-center col-start-${
                (index % 4) + 1
              } lg:col-start-${3 + (index % 6) + 1}`}
              key={index}
              href={obterLinkParaSkills([relacionado])}
            >
              <div
                className="flex flex-col items-center justify-center text-center"
                key={index}
              >
                <SkillLogo
                  svgSx={{
                    path: {
                      fill: "white",
                    },
                  }}
                  className="w-10 md:w-12"
                  id={relacionado.id}
                />
                <div className="mt-2">{relacionado.nome}</div>
              </div>
            </a>
          ))}
        </div>
        <div className="mt-6 col-span-full">
          <Botao className="w-auto md:w-80" variant="secundario">
            Contratar Agora
          </Botao>
        </div>
      </div>
    </div>
  )
}
