import { Botao, Cartao } from "@xapps/design-system"
import { CabecalhoDoBloco } from "./CabecalhoDoBloco"
import IconeVantagens1 from "@images/svg/vantagens1.svg"
import IconeVantagens2 from "@images/svg/vantagens2.svg"
import IconeVantagens3 from "@images/svg/vantagens3.svg"
import React from "react"

export function Vantagens({ aoClicarNoBotao }) {
  const cartoes = [
    <Cartao
      imagem={<IconeVantagens1 className="w-24 h-24" />}
      titulo={"Seleção"}
      className="h-full flex flex-col items-center py-6"
      descricao={
        <p className="text-sm lg:text-xl">
          {
            "Os desenvolvedores da X-Apps não são terceirizados. Eles fazem parte do time efetivo de profissionais. Assim, controlamos nosso padrão de qualidade."
          }
        </p>
      }
    />,
    <Cartao
      imagem={<IconeVantagens2 className="w-24 h-24" />}
      titulo={"Equipamentos"}
      className="h-full flex flex-col items-center py-6"
      descricao={
        <p className="text-sm lg:text-xl">
          {
            "Todos os nossos desenvolvedores utilizam MacBooks e notebooks de última geração. Você não vai precisar se preocupar com a compra de novos equipamentos."
          }
        </p>
      }
    />,
    <Cartao
      imagem={<IconeVantagens3 className="w-24 h-24" />}
      titulo={"Avaliação"}
      className="h-full flex flex-col items-center py-6"
      descricao={
        <p className="text-sm lg:text-xl">
          Estamos em contato regularmente com nossos profissionais para entender
          como podemos aprimorar sua experiência com nossos desenvolvedores.
        </p>
      }
    />,
  ]

  return (
    <div
      id="vantagens"
      className="col-span-12 min-h-screen bg-gray-100 grid grid-cols-12 gap-4 text-center  py-24 lg:py-36 px-6 lg:px-32"
    >
      <div className="container m-auto col-span-12">
        <CabecalhoDoBloco
          titulo={
            <>
              A <strong>burocracia</strong>, deixa com a <strong>gente</strong>:
            </>
          }
        />

        <div className="col-span-full flex justify-center grid grid-cols-12 gap-4 mt-9 ">
          {cartoes.map((cartao, index) => (
            <div key={index} className="col-span-full lg:col-span-4">
              {cartao}
            </div>
          ))}
        </div>
        <div className="col-span-full mt-20">
          <Botao onClick={aoClicarNoBotao}>Quero contratar agora!</Botao>
        </div>
      </div>
    </div>
  )
}
