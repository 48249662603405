import { Carrossel } from "@xapps/design-system"
import { useState } from "react"
import { CabecalhoDoBloco } from "./CabecalhoDoBloco"
import dadosDaAnimacao1 from "@images/vantagem1.json"
import dadosDaAnimacao2 from "@images/vantagem2.json"
import dadosDaAnimacao3 from "@images/vantagem3.json"
import dadosDaAnimacaoMobile1 from "@images/vantagemMobile1.json"
import dadosDaAnimacaoMobile2 from "@images/vantagemMobile2.json"
import dadosDaAnimacaoMobile3 from "@images/vantagemMobile3.json"
import React from "react"
import LottieAnimation from "@components/LottieAnimation"
import useMedia from "use-media"
import { Box } from "rebass"

export function ComoFunciona() {
  const [slideVisivel, mudarDeSlide] = useState(0)
  const estaNaDimensaoDeDesktop = useMedia({ minWidth: "64rem" })

  const slides = [
    <ComoFunciona.Slide
      key={1}
      Animacao={() => (
        <LottieAnimation
          animation={
            estaNaDimensaoDeDesktop ? dadosDaAnimacao1 : dadosDaAnimacaoMobile1
          }
          height="100%"
          width="100%"
        />
      )}
      titulo={
        <>
          Selecione as tecnologias que sua
          <br /> empresa utiliza no dia a dia
        </>
      }
      texto={
        <>
          <p className="mb-4">
            Isso vai nos ajudar a encontrar desenvolvedores especializados nas
            tecnologias que você escolheu.
          </p>
          <p>
            Não encontrou o que procurava? Solicite uma Busca Personalizada.
            Vamos entrar em contato para entender de que tipo de profissional
            você precisa.
          </p>
        </>
      }
    />,
    <ComoFunciona.Slide
      key={2}
      Animacao={() => (
        <LottieAnimation
          animation={
            estaNaDimensaoDeDesktop ? dadosDaAnimacao2 : dadosDaAnimacaoMobile2
          }
          height="100%"
          width="100%"
        />
      )}
      titulo={"Monte sua squad"}
      texto={
        <>
          <p className="mb-4">
            Adicione os desenvolvedores de acordo com a quantidade e a
            especialidade de que você precisa.
          </p>
          <p>
            Lembre-se de confirmar no relógio quantos dias você ainda te
            envolvedores preferidos e antecipar sua squad.
          </p>
        </>
      }
    />,
    <ComoFunciona.Slide
      key={3}
      Animacao={() => (
        <LottieAnimation
          animation={
            estaNaDimensaoDeDesktop ? dadosDaAnimacao3 : dadosDaAnimacaoMobile3
          }
          height="100%"
          width="100%"
        />
      )}
      titulo={"Salve seu time de desenvolvedores"}
      texto={
        "Agora, você só precisa confirmar sua squad e colocar suas informações de contato. Depois, basta assinar o contrato para começar seu período de uso."
      }
    />,
  ]
  return (
    <div
      id="comoFunciona"
      className="col-span-full min-h-screen bg-white grid grid-cols-12 gap-4 text-center py-44 px-6 lg:px-32"
    >
      <div className="container mx-auto col-span-full">
        <div className="grid grid-cols-12 gap-4 col-span-full container mx-auto mt-16">
          <CabecalhoDoBloco
            titulo={
              <>
                <strong>Contrate</strong> seu <strong>time</strong> de
                desenvolvedores
                <br />
                em <strong>poucos minutos</strong>
              </>
            }
            subtitulo="Como funciona"
          />

          <div className="col-span-full">
            <Carrossel
              indiceDoVisivel={slideVisivel}
              aoMudarDePagina={mudarDeSlide}
            >
              {slides}
            </Carrossel>
          </div>
        </div>
      </div>
    </div>
  )
}

ComoFunciona.Slide = ({ Animacao, titulo, texto }) => {
  return (
    <div className="col-span-full grid grid-cols-12 gap-4 mt-2 lg:mt-8">
      <div className="col-start-5 lg:col-start-1 col-span-4 lg:col-span-7 flex justify-center">
        <Box>
          <Animacao />
        </Box>
      </div>
      <div className="col-span-full lg:col-span-5 text-center lg:text-left  mt-6 lg:mt-20">
        <h3 className="text-2xl text-primary-dark font-bold">{titulo}</h3>
        <p className="mt-4 text-gray-500">{texto}</p>
      </div>
    </div>
  )
}
