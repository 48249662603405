import { Botao } from "@xapps/design-system"
import { Eye } from "react-feather"
import animacaoDoDev from "@images/techsEmAltaProcura.json"
import React from "react"
import classnames from "classnames"
import { SkillLogo } from "./SkillLogo"
import LottieAnimation from "@components/LottieAnimation"
import { obterLinkParaSkills } from "@utils/obterLinkParaSkills"

const techsEmAltaProcura = [
  { nome: "Flutter", id: "5f1b0331afe9400020f9c926" },
  { nome: "React", id: "5f1b0331afe9400020f9c914" },
  { nome: "React Native", id: "5f1b0332afe9400020f9c92d" },
  { nome: "Node.JS", id: "5f1b0332afe9400020f9c934" },
  { nome: "Java", id: "5fb687ba8e533700427aba04" },
  { nome: "Python", id: "5f1b0332afe9400020f9c93f" },
]

export function TecnologiasEmAltaProcura({ aoClicarNoBotao, maisProcuradas }) {
  return (
    <div
      id="tecnologiasEmAltaProcura"
      className="col-span-full bg-gradient-to-tr from-light-blue text-white grid grid-cols-12 gap-x-4 "
    >
      <div className="mt-20 lg:mt-0 relative container mx-auto grid grid-cols-12 gap-x-4 col-span-full py-24 lg:py-32 px-6 lg:px-32">
        <div className="-mt-64 mb-6 col-span-full lg:col-span-6">
          <div className="w-64 md:96 lg:w-auto mx-auto flex justify-center">
            <LottieAnimation
              width={"100%"}
              height={"100%"}
              animation={animacaoDoDev}
            />
          </div>
        </div>

        <div className="col-span-full lg:col-span-6 text-center">
          <div className="flex flex-col justify-center items-center h-full">
            <h3 className="uppercase font-bold ">É melhor correr</h3>
            <h2 className="font-bold text-4xl mt-6">
              Tecnologias em alta procura
            </h2>

            <div className="grid grid-cols-4 lg:grid-cols-6 gap-4 mt-6">
              {maisProcuradas?.map((tech, indice) => (
                <a
                  className={classnames({
                    "hidden lg:block": indice > 3,
                  })}
                  key={indice}
                  href={obterLinkParaSkills([tech])}
                >
                  <div className="flex  flex-col items-center">
                    <SkillLogo
                      svgSx={{
                        path: {
                          fill: "white",
                        },
                      }}
                      className="flex items-center justify-center w-8 h-8 "
                      id={tech.id}
                    />
                    <div className="mt-4">{tech.nome}</div>
                  </div>
                </a>
              ))}
            </div>

            <div className="flex justify-center text-sm lg:text-base my-8">
              <Eye className="hidden lg:block mr-2" />
              {parseInt(Math.random() * 15 + 6)} empresas estão pesquisando
              neste momento
            </div>

            <Botao onClick={aoClicarNoBotao}>Contrate agora mesmo</Botao>
          </div>
        </div>
      </div>
    </div>
  )
}
