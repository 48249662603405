import { Botao, Logo } from "@xapps/design-system"
import { Menu } from "./Menu"
import mockupDevSquad from "./mockupDevSquad.png"
import React, { useState } from "react"
import { Menu as IconeDeMenu, X } from "react-feather"

export function Cabecalho({ aoClicarNoBotao, titulo, subtitulo }) {
  const [menuEstaAberto, definirSeMenuEstaAberto] = useState(false)

  return (
    <header className="py-6 lg:py-12 grid grid-cols-12 gap-4 col-span-12 bg-landing-page bg-opacity-10 text-white px-6 lg:px-32">
      <div className="col-span-full mx-auto container grid grid-cols-12 gap-4">
        <div className="col-span-5 lg:col-span-2 flex items-center">
          <a href={process.env.GATSBY_SITE_URL}>
            <Logo />
          </a>
        </div>
        <div className="col-span-7 lg:col-span-10 block lg:hidden ml-auto">
          <div onClick={() => definirSeMenuEstaAberto(anterior => !anterior)}>
            {menuEstaAberto ? <X /> : <IconeDeMenu />}
          </div>
        </div>
        <Menu
          estaAberto={menuEstaAberto}
          onClickCallToAction={aoClicarNoBotao}
        />
        <div className="col-span-10 col-start-2 lg:col-start-1 lg:col-span-full mt-0 lg:mt-20 text-center lg:text-left">
          <h1
            className="font-bold text-2xl lg:text-5xl"
            dangerouslySetInnerHTML={{ __html: titulo }}
          />
        </div>
        <div className="col-span-full grid grid-cols-12 gap-x-4 mt-0 lg:mt-16 text-center lg:text-left">
          <div className="col-span-full lg:col-span-5">
            <div
              className="text-sm lg:text-lg mb-8"
              dangerouslySetInnerHTML={{ __html: subtitulo }}
            />

            <Botao onClick={aoClicarNoBotao} className="w-60 lg:w-80">
              Comece agora
            </Botao>
          </div>

          <div className="col-start-1 col-span-full lg:col-start-7 lg:col-span-6 mt-8 lg:mt-0 -mb-40 z-50">
            <div className="mt-8 flex justify-center">
              <div
                style={{
                  // aspect-ratio: 16:9
                  paddingTop: "56.25%",
                }}
                className="relative w-full"
              >
                <iframe
                  src="https://player.vimeo.com/video/510799788"
                  frameborder="0"
                  className="absolute top-0 left-0 w-full h-full shadow-xl "
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
