import { Logo } from "@xapps/design-system"
import React from "react"

export function Rodape() {
  return (
    <div className="bg-primary-dark text-center text-white text-sm md:text-base col-span-full py-14">
      <div className="flex flex-col items-center">
        <a href={process.env.GATSBY_SITE_URL}>
          <Logo />
        </a>
        <div className="mt-2">
          {new Date().getFullYear()} - Todos os direitos reservados
        </div>
      </div>
    </div>
  )
}
