import { Botao } from "@xapps/design-system"
import React from "react"
import className from "classnames"
const items = [
  { label: "Como funciona", anchor: "comoFunciona" },
  { label: "Vantagens", anchor: "vantagens" },
  { label: "Quem já contratou", anchor: "quemConfia" },
  { label: "Dúvidas frequentes", anchor: "duvidasFrequentes" },
]

export function Menu({ onClickCallToAction, estaAberto }) {
  return (
    <nav className="col-span-full lg:col-span-10">
      <ul
        className={className(
          "lg:flex flex-wrap items-center justify-start lg:justify-end ",
          {
            flex: estaAberto,
            hidden: !estaAberto,
          }
        )}
      >
        {items.map(item => (
          <li
            className="mr-0 lg:mr-8 w-full lg:w-auto mb-4 lg:mb-0 text-blue-text hover:text-white"
            key={item.label}
          >
            <a href={`#${item.anchor}`}> {item.label}</a>
          </li>
        ))}
        <Botao onClick={onClickCallToAction} variant="secundario">
          Contrate
        </Botao>
      </ul>
    </nav>
  )
}
