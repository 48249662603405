import { graphql } from "gatsby"
import React from "react"
import { DevsquadLandingPage } from "@components/DevsquadLandingPage"
import SEO from "@components/Seo/Seo.component"
import { useStoreCampaignVariablesInCookies } from "@utils/hooks/useStoreCampaignVariablesInCookies"

function LandingPageDevsquadTemplate({ data }) {
  const {
    markdownRemark: { frontmatter },
  } = data
  useStoreCampaignVariablesInCookies()

  return (
    <DevsquadLandingPage {...frontmatter}>
      <SEO
        keywords={frontmatter.keywords}
        title={frontmatter.plainTitle}
        description={frontmatter.plainSubtitle}
      />
    </DevsquadLandingPage>
  )
}

export const query = graphql`
  query LandingPagesDevsquad($slug: String!) {
    markdownRemark(
      fileAbsolutePath: { glob: "**/content/devsquad-landing-pages/*.md" }
      fields: { slug: { eq: $slug } }
    ) {
      frontmatter {
        title: markdownField(from: "title")
        subtitle: markdownField(from: "subtitle")
        plainTitle: plainText(from: "title")
        plainSubtitle: plainText(from: "subtitle")
        relatedSkills {
          id
          nome
        }
        otherSkills {
          id
          nome
        }
        hotSkills {
          id
          nome
        }
        slug
      }
    }
  }
`

export default LandingPageDevsquadTemplate
