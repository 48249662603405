import React from "react"
import { Cabecalho } from "./landing-page/Cabecalho"
import { ComoFunciona } from "./landing-page/ComoFunciona"
import { FAQ } from "./landing-page/FAQ"
import { Funcionalidades } from "./landing-page/Funcionalidades"
import { QuemConfia } from "./landing-page/QuemConfia"
import { QuemUsa } from "./landing-page/QuemUsa"
import { Rodape } from "./landing-page/Rodape"
import { TecnologiasEmAltaProcura } from "./landing-page/TecnologiasEmAltaProcura"
import { Vantagens } from "./landing-page/Vantagens"
import { obterLinkParaSkills } from "../../utils/obterLinkParaSkills"

export function DevsquadLandingPage({
  title,
  subtitle,
  relatedSkills = [],
  otherSkills = [],
  hotSkills = [],
  children,
}) {
  const irParaOMercado = () => {
    if (window.location.pathname === '/lp-squad-as-a-service') {
      window.location.href = `${process.env.GATSBY_SITE_URL}/contrate-squads/tecnologias`
    } else {
      window.location.href = obterLinkParaSkills(relatedSkills)
    }
  }

  return (
    <main className="font-sans bg-primary grid grid-cols-12 gap-x-4">
      {children}
      <Cabecalho
        titulo={title}
        subtitulo={subtitle}
        aoClicarNoBotao={irParaOMercado}
      />
      <ComoFunciona />
      <Vantagens aoClicarNoBotao={irParaOMercado} />
      <QuemConfia />
      <QuemUsa
        idDaSkill={relatedSkills.length ? relatedSkills[0].id : null}
        nomeDaTech={relatedSkills.length ? relatedSkills[0].nome : null}
        aoClicarNoBotao={irParaOMercado}
        outrasTecnologias={otherSkills}
      />
      <Funcionalidades aoClicarNaChamada={irParaOMercado} />
      <FAQ />
      <TecnologiasEmAltaProcura
        maisProcuradas={hotSkills}
        aoClicarNoBotao={irParaOMercado}
      />
      <Rodape />
    </main>
  )
}
