import { CarrosselExplosivo } from "@xapps/design-system"
import { CabecalhoDoBloco } from "./CabecalhoDoBloco"
import React from "react"
import { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Box } from "rebass"

export function QuemConfia() {
  const [indiceDoDestacado, destacar] = useState(0)
  const { testimonies } = useStaticQuery(graphql`
    query {
      testimonies: allMarkdownRemark(
        filter: { fileAbsolutePath: { glob: "**/content/testimonials/*.md" } }
        sort: { fields: frontmatter___order }
      ) {
        edges {
          node {
            id
            frontmatter {
              company
              languages {
                language
                description
                clientName
                clientPosition
                predicate
              }
              logo {
                childSvg {
                  content
                }
              }
            }
          }
        }
      }
    }
  `)

  const algunsDepoimentos = testimonies.edges
    .map(testimonial => ({
      id: testimonial.node.id,
      ...testimonial.node.frontmatter,
      translation: testimonial.node.frontmatter.languages.find(
        language => language.language === process.env.GATSBY_LANGUAGE
      ),
    }))
    .filter(testimonial => testimonial.translation)
    .slice(0, 5)

  const logos = algunsDepoimentos.map((depoimento, index) => (
    <Box
      key={depoimento.id}
      sx={{
        display: "inline-flex",
        justifyContent: "center",
        width: "100%",
      }}
      dangerouslySetInnerHTML={{
        __html: depoimento.logo.childSvg.content,
      }}
    />
  ))

  const depoimentos = algunsDepoimentos.map(depoimento => ({
    predicado: depoimento.translation.predicate,
    autor: depoimento.translation.clientName,
    texto: depoimento.translation.description,
    cargo: depoimento.translation.clientPosition,
  }))

  const depoimentoAtivo = depoimentos[indiceDoDestacado]

  return (
    <div
      id="quemConfia"
      className="bg-white min-h-screen col-span-12 grid grid-cols-12 gap-x-4 py-24 lg:py-32 px-6 lg:px-32"
    >
      <div className="col-span-12 grid grid-cols-12 gap-x-4 container mx-auto">
        <CabecalhoDoBloco
          titulo={
            <>
              As <strong>maiores</strong> empresas do <strong>Brasil</strong>
              <br /> e do <strong>mundo</strong>
            </>
          }
          subtitulo="Sabe quem confia na X-Apps?"
        />
        <div className="container mx-auto col-span-full lg:col-span-6">
          <CarrosselExplosivo
            indiceDoDestacado={indiceDoDestacado}
            aoMudarDeFoco={destacar}
          >
            {logos}
          </CarrosselExplosivo>
        </div>
        <div className="col-span-full lg:col-span-6 text-center lg:text-left h-64 lg:h-96">
          <div className="font-bold">
            <h3 className="text-sm lg:text-lg text-primary-dark">
              Quem disse isso:
            </h3>
            <h2 className="text-lg lg:text-2xl text-primary">
              {depoimentoAtivo.predicado}
            </h2>
          </div>
          <p
            className="mt-3 text-gray-700 text-sm lg:text-xl"
            dangerouslySetInnerHTML={{ __html: `“${depoimentoAtivo.texto}”` }}
          />
          <div className="font-bold mt-6">
            <p className="text-xl lg:text-2xl text-primary">
              {depoimentoAtivo.autor}
            </p>
            <p className="text-sm lg:text-lg text-primary-dark">
              {depoimentoAtivo.cargo}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
