import { Aba, Abas, Botao } from "@xapps/design-system"
import React from "react"
import Funcionalidade1 from "@images/svg/funcionalidade1.svg"
import Funcionalidade2 from "@images/svg/funcionalidade2.svg"
import Funcionalidade3 from "@images/svg/funcionalidade3.svg"
import Funcionalidade4 from "@images/svg/funcionalidade4.svg"
import Funcionalidade5 from "@images/svg/funcionalidade5.svg"
import Funcionalidade6 from "@images/svg/funcionalidade6.svg"
import { CabecalhoDoBloco } from "./CabecalhoDoBloco"

const Titulo = ({ children }) => (
  <h2 className="text-2xl text-primary-dark my-4 font-bold">{children}</h2>
)

const slides = [
  {
    titulo: "Segurança",
    Imagem: Funcionalidade1,
    conteudo: (
      <>
        <Titulo>Contrato ajustado de acordo com a LGPD</Titulo>
        <p>
          Seus dados estão seguros com a gente. Conte com cláusulas de sigilo e
          de confidencialidade para proteger o que é seu.
          <br />
          <br />
          Receba também uma cópia de segurança no seu e-mail para um acesso
          fácil e rápido.
        </p>
      </>
    ),
  },
  {
    titulo: "Praticidade",
    Imagem: Funcionalidade2,
    conteudo: (
      <>
        <Titulo>Tudo em um só lugar</Titulo>
        <p>
          Deixe a papelada de lado. Com a X-Apps, todo o processo de contratação
          de desenvolvedores é feito digitalmente.
          <br />
          <br />
          Você pode salvar uma cópia do documento e compartilhar com quem
          quiser.
        </p>
      </>
    ),
  },
  {
    titulo: "Facilidade",
    Imagem: Funcionalidade3,
    conteudo: (
      <>
        <Titulo>Pague por cada desenvolvedor ou pela squad inteira</Titulo>
        <p>
          Você pode pagar por cada profissional individualmente para liberar os
          desenvolvedores escolhidos para atuar no seu projeto. Da mesma forma,
          você pode pagar por toda a squad para liberar todo o seu time de
          profissionais de TI.
        </p>
        <Titulo>Pague somente pela horas produtivas</Titulo>
        <p>
          Depois de contratar seu squad, você vai pagar apenas as horas em que o
          profissional desenvolveu para você. Monitore os sites acessados e
          defina quais tipos de busca são produtivas ou não para o projeto.
        </p>
      </>
    ),
  },
  {
    titulo: "Suporte",
    Imagem: Funcionalidade4,
    conteudo: (
      <>
        <Titulo>Adicione ou substitua desenvolvedores quando quiser</Titulo>
        <p>
          Caso precise adicionar ou substituir profissionais da sua squad,
          converse com o nosso time de suporte.
          <br />
          <br />
          Vamos entender sua necessidade para saber como podemos ajudar você da
          melhor forma.
        </p>
      </>
    ),
  },
  {
    titulo: "Autonomia",
    Imagem: Funcionalidade5,
    conteudo: (
      <>
        <Titulo>Área do cliente</Titulo>
        <p>
          Gerencie tudo o que precisa. Tenha acesso ao seu histórico de squads e
          saiba quais times estão ativos e inativos dentro do seu projeto.
          <br />
          <br />
          Saiba também quais desenvolvedores ainda podem ser contratados e
          descubra quantos dias você ainda tem para adicioná-los ao seu projeto.
        </p>
      </>
    ),
  },
  {
    titulo: "Qualidade",
    Imagem: Funcionalidade6,
    conteudo: (
      <>
        <Titulo>Desenvolvedores selecionados</Titulo>
        <p>
          Todos os nossos profissionais passam por avaliações comportamentais e
          técnicas com frequência.
          <br />
          <br />
          Além disso, selecionamos apenas desenvolvedores de nível pleno e
          sênior para que você tenha acesso aos profissionais mais renomados do
          mercado.
        </p>
        <Titulo>Time efetivo</Titulo>
        <p>
          Os desenvolvedores que você contrata com a X-Apps fazem parte do time
          efetivo da empresa. Não trabalhamos com desenvolvedores terceirizados.
          <br />
          <br />
          Acreditamos que assim conseguimos nos manter mais próximos dos
          profissionais para garantir o padrão de qualidade que sua empresa
          merece.
        </p>
      </>
    ),
  },
]

const Slide = ({ Imagem, conteudo, aoClicarNaChamada }) => {
  return (
    <div className="flex flex-wrap pt-12 text-gray1 grid grid-cols-1 lg:grid-cols-2 gap-4">
      <span className="flex justify-center">
        <Imagem className="h-32 lg:h-96 " />
      </span>
      <div className="text-center ml-4 lg:text-left">
        <div className="mb-10">{conteudo}</div>
        <Botao onClick={aoClicarNaChamada}>Legal. Quero começar agora!</Botao>
      </div>
    </div>
  )
}

export function Funcionalidades({ aoClicarNaChamada }) {
  return (
    <div className="min-h-screen bg-white col-span-full">
      <div className="container mx-auto py-24 lg:py-32 px-6 lg:px-32">
        <CabecalhoDoBloco
          subtitulo="Funcionalidades"
          titulo={
            <>
              Quando você contrata com a <strong>X-Apps</strong>, a gente
              <br /> te oferece <strong>bem mais</strong> que um{" "}
              <strong>time de tecnologia:</strong>
            </>
          }
        />
        <div className="mt-8">
          <Abas
            sxDoTitulo={{
              flex: 1,
            }}
          >
            {slides?.map((slide, indice) => (
              <Aba key={indice} label={slide.titulo}>
                <Slide aoClicarNaChamada={aoClicarNaChamada} {...slide} />
              </Aba>
            ))}
          </Abas>
        </div>
      </div>
    </div>
  )
}
