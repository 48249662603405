import React from "react"
import { ReactSVG } from "react-svg"
import { Box } from "rebass"
import DefaultSkillLogo from "@images/svg/DefaultSkillLogo.svg"

export function SkillLogo({ id, svgSx, ...props }) {
  return (
    <Box
      sx={{
        svg: {
          width: "100%",
          height: "100%",
          ...svgSx,
        },
      }}
      {...props}
    >
      <ReactSVG
        src={`https://www.x-apps.com.br/contrate-squads/logos/${id}.svg`}
        fallback={() => <DefaultSkillLogo />}
        loading={() => <DefaultSkillLogo />}
      />
    </Box>
  )
}
