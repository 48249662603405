import LogoPayPal from "@images/svg/paypal.svg"
import LogoUber from "@images/svg/uber.svg"
import LogoGoogle from "@images/svg/google.svg"
import LogoAirBNB from "@images/svg/airbnb.svg"
import { Botao } from "@xapps/design-system"
import React from "react"
import { OutrasTecnologias } from "./OutrasTecnologias"
import { SkillLogo } from "./SkillLogo"

export function QuemUsa({
  aoClicarNoBotao,
  idDaSkill,
  nomeDaTech,
  outrasTecnologias,
}) {
  const marcas = [LogoPayPal, LogoAirBNB, LogoUber, LogoGoogle]
  return (
    <div className="min-h-screen col-span-12">
      <div className="object-cover bg-cover bg-landing-page-2">
        <div className="container mx-auto grid grid-cols-12 gap-x-4 py-24 md:py-32 px-6 md:px-32">
          <div className="text-white text-center lg:text-left col-span-full lg:col-span-5 ">
            <h2 className="uppercase font-bold text-sm md:text-lg">
              DESENVOLVEDORES DE NÍVEL PLENO E SÊNIOR
            </h2>
            <h1 className="font-bold text-2xl md:text-4xl mt-6">
              Back-end. Front-end.
              <br /> Full stack.
            </h1>
            <p className="text-sm md:text-lg w-auto mt-7">
              Faça como as maiores empresas de tecnologia do mundo. Contrate
              desenvolvedores especializados no que há de mais moderno e
              avançado em desenvolvimento de softwares e aplicativos.{" "}
            </p>
          </div>
          <div className="text-white col-span-full lg:col-span-7 text-center mt-36 lg:mt-0">
            <div className="relative pt-32 bg-gradient-to-tr from-light-blue flex flex-col items-center justify-center to-transparent rounded-xl pb-10 px-12">
              <div className="absolute -top-24 bg-white  p-8 inline-flex justify-center items-center shadow-xl w-44 h-44 rounded-full">
                {
                  <SkillLogo
                    svgStyle={{
                      width: "100%",
                      height: "100%",
                    }}
                    className="w-full h-full"
                    id={idDaSkill}
                  />
                }
              </div>
              <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-10 justify-items-center">
                {marcas.map((Icone, index) => (
                  <Icone className="max-w-full max-h-full" key={index} />
                ))}
              </div>
              <p className="mt-6 mb-6">
                Estas empresas executam aplicações em tempo real e gerenciam a
                transmissão de dados com a máxima eficácia com a ajuda{" "}
                {nomeDaTech ? `do ${nomeDaTech}` : "diversas tecnologias"}.
              </p>
              <Botao onClick={aoClicarNoBotao}>
                Contrate desenvolvedores {nomeDaTech ? nomeDaTech : ""}
              </Botao>
            </div>
          </div>
        </div>
      </div>
      <OutrasTecnologias relacionadas={outrasTecnologias} />
    </div>
  )
}
