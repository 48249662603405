import { Acordeao } from "@xapps/design-system"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Box } from "rebass"
import { CabecalhoDoBloco } from "./CabecalhoDoBloco"

export function FAQ() {
  const { allMarkdownRemark } = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { glob: "**/content/faq/*.md" } }
      ) {
        nodes {
          frontmatter {
            pergunta
            resposta: markdownField(from: "resposta")
          }
        }
      }
    }
  `)

  const questoes = allMarkdownRemark.nodes.map((node, index) => ({
    titulo: (
      <p className="text-sm mr-2 md:text-lg">{`${index + 1}. ${
        node.frontmatter.pergunta
      }`}</p>
    ),
    texto: (
      <Box
        className="text-sm md:text-base"
        sx={{
          width: ["100%", "60%"],
          p: {
            my: 2,
          },
        }}
        dangerouslySetInnerHTML={{ __html: node.frontmatter.resposta }}
      />
    ),
  }))

  return (
    <div
      id="duvidasFrequentes"
      className="col-span-full min-h-screen py-24 md:py-32 bg-gray-100"
    >
      <div className="container mx-auto px-6 md:px-32">
        <CabecalhoDoBloco titulo={<strong>Dúvidas Frequentes</strong>} />
        <div className="text-left text-sm mt-8">
          <Acordeao
            propsDoContainer={{ sx: { bg: "white" } }}
            items={questoes?.map(questao => ({
              rotulo: questao.titulo,
              conteudo: questao.texto,
            }))}
          />
        </div>
      </div>
    </div>
  )
}
