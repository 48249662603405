import React from "react"

export function CabecalhoDoBloco({ titulo, subtitulo = null }) {
  return (
    <div className="col-span-full text-center">
      {subtitulo && (
        <h2 className="uppercase font-bold text-sm md:text-base text-primary-dark">
          {subtitulo}
        </h2>
      )}
      <h1 className="col-span-full text-2xl md:text-4xl text-primary">
        {titulo}
      </h1>
    </div>
  )
}
